import {Card} from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';


function SubIndex(props) {  
    const columns = [{
		dataField: 'name',
		text: 'Dimension',
		sort: true
	  }, {
		dataField: 'value',
		text: 'Index',
		sort: true,
        formatter: idxFormatter
	  }, {
		dataField: 'chg',
		text: 'Change %',
        formatter: chgFormatter
	  }]; 
    
    function chgFormatter(cell, row) {
        if (row.chg<0) {
            return (
                <div>
                    <i class="bi bi-arrow-down" style={ {color: 'red' } }/>
                    <strong style={ { color: 'red' } }> { Math.abs(cell).toFixed(2) }%</strong>
                </div>
            );
        }else if(row.chg>0){
            return (
                <div>
                    <i class="bi bi-arrow-up" style={ {color: 'green' } }/>
                    <strong style={ {background:'green', color: 'white' } }> { cell.toFixed(2) }%</strong>
                </div>
            );            
        }else{
            return (
                <div>
                    <strong style={ { color: 'black' } }> ---</strong>
                </div>
            );            
        }
    }

    function idxFormatter(cell, row) {
        let bgColor='grey';
        if (row.value>7.0) {
            bgColor='green';
        }else if(row.value>4.0){
            bgColor='orange';
        }else{
            bgColor='red';
        }

        return(
          <span  
            className="fw-bold" 
            style={{color: 'white',background:bgColor}}
            >{Number(cell).toFixed(1)}</span>
        )

    }
   
    return (
        <Card className='m-2  border-2 flex-fill' border="dark"  style={{height:'400px'}}>
            <Card.Body>
            <BootstrapTable 
                keyField='name' 
                data={ props.subIdx } 
                columns={ columns } 
                bordered={ false } 
                noDataIndication="Table is Empty" 
                hover condensed/>
            </Card.Body>
        </Card>
    );
}

export default SubIndex;