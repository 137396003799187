import React, { useState, useEffect } from "react";
import {Container,Row,Col} from 'react-bootstrap';
import axios from 'axios';
import SubIndex from "./SubIndex";
import CurrentIndex from "./CurrentIndex";
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';

function Wbi() {
    const [subClass, setSubClass] = useState([]);
    const [current, setCurrent] = useState([]);

	const baseurl = "https://wbi-api.innovaderslab.com/wbi";

	
    useEffect(() => {
        axios.get(baseurl,
			{
				mode: 'no-cors',
				headers: {
					'Access-Control-Allow-Origin': '*',
					'X-Requested-With': null
				}
			}	
			).then((response) => {
			let tmpCurrent=[];
			tmpCurrent.push({
				idx:response.data.index.index,
				lastWeekChg:Number(response.data.index.lastWeekChange),
				lastMonthChg:Number(response.data.index.lastMonthChange),
				location:response.data.index.location,
				updateDt:response.data.index.udapteDt
			});
			setCurrent(tmpCurrent);

			let tmp=[];
			response.data.index.subClass.forEach(
				(data)=>
					tmp.push({name:data.name ,value:data.index ,chg:data.lastWeekChange})
				)

			setSubClass(tmp);
        })
    }, []);
  
    return (
		<>
			<Navbar bg="secondary" data-bs-theme="dark">
				<Container>
				<Navbar.Brand href="#home">WBI</Navbar.Brand>
				<Nav className="me-auto">
					<Nav.Link href="#home">Index</Nav.Link>
				</Nav>
				</Container>
			</Navbar>
			<br />		
			<Container>
				<Row>
					<Col  xs={12} sm={12} md={6}><CurrentIndex idx={current} /></Col>
					<Col  xs={12} sm={12} md={6}><SubIndex subIdx={subClass} /></Col>
				</Row>
			</Container>
			<Navbar bg="secondary" data-bs-theme="dark" className="fixed-bottom">
				<Container>
					<Row>
						<Col class="col-12 center-block text-center">All Rights Reserved</Col>
					</Row>
				</Container>
			</Navbar>
		</>
    );
}

export default Wbi;