import React from "react";
import {
  Routes,
  Route
} from "react-router-dom";

import Home from '../components/landing/Home';

export function RenderRoutes({ routes }) {
  return (
    <Routes>
      <Route path="/" key="ROOT" exact element={<Home urlKey="root" />} />

      <Route element={() => <h1>Not Found!</h1>} />
    </Routes>
  );
}