import React, { useState,useEffect } from "react";
import {Card,Row,Col} from 'react-bootstrap';
import { format } from 'date-fns';

function CurrentIndex(props) {          
    const [idxColor, setIdxColor] = useState("grey");

    function chgFormat(val) {
        if (val<0) {
            return (
                <span>
                    <i class="bi bi-arrow-down" style={ {color: 'red' } }/>
                    <strong style={ {color: 'red' } }> { Math.abs(val).toFixed(2) }%</strong>
                </span>
            );
        }else if(val>0){
            return (
                <span>
                    <i class="bi bi-arrow-up" style={ {color: 'green' } }/>
                    <strong style={ {color: 'green' } }> { val.toFixed(2) }%</strong>
                </span>
            );            
        }else{
            return (
                <span>
                    <strong style={ { color: 'black' } }> ---</strong>
                </span>
            );            
        }
    }  

    useEffect(() => {

        props.idx.map((obj)=>{
            //set index color
            if(obj.idx>=7.0){
                    setIdxColor("green");
                }else if(obj.idx>=4.0){
                    setIdxColor("orange");
                }else{
                    setIdxColor("red");
                }
            return(<></>); 
        })

    }, [props]);

    return(
        props.idx.map((obj)=>{ return (
            <Card className='m-2  border-2 flex-fill' border="dark"  style={{height:'400px'}}>
                <Card.Body>
                    <Row>
                        <Col className="h2">Well Being Index (WBI)</Col>
                    </Row>
                    <Row>
                        <Col>
                            <div  
                                className="h1 m-5 d-flex align-items-center text-center fw-bold" 
                                style={{width:'70px',height:'70px',color: 'white',background:idxColor}}
                                >{Number(obj.idx).toFixed(1)}</div>
                        </Col>
                        <Col>
                            <Row className="h5 mt-5">
                                <Col>Last Week</Col>
                                <Col>{chgFormat(obj.lastWeekChg)}</Col>
                            </Row>
                            <Row className="h5 mt-2">
                                <Col>Last Month</Col>
                                <Col>{chgFormat(obj.lastMonthChg)}</Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row className="h6">
                        <Col>Location : {obj.location}</Col>
                    </Row>
                    <Row className="h6">
                        <Col>Latest Update : {format(obj.updateDt, 'do MMMM yyyy, hh:mm a')}</Col>
                    </Row>
                </Card.Body>
            </Card>
        )})
    );
}

export default CurrentIndex;